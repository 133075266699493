
.timeline {
    /* border-left: 4px solid #333; */
    padding-left: 20px;
    margin-bottom:50px;
    text-align: center;
}

.timeline-item {
    position: relative;
    padding: 10px 0;
}

.timeline-date {
    position: absolute;
    left: -125px;
    width: 120px;
    text-align: right;
    font-weight: bold;
}

.timeline-content h4 {
    margin: 0;
    font-size: 1.25em;
}

.timeline-content p {
    margin: 5px 0 0;
    font-size: 0.875em;
}

.arweave{
    text-decoration: none;
    color: white;
}
.arweave:hover{
    color:#f7931a;
}

@media only screen and (max-width: 600px) {
    .timeline {
        padding: 10px;
    }
    .timeline-item {
        padding: 5px;
        font-size: 14px; /* smaller font size for smaller screens */
    }
    .timeline-content p {
        flex-direction: column; /* stack the description and date vertically */
        align-items: flex-start;
    }
    .arweave, .btc-price {
        display: block; /* makes each element take full width */
    }
}

