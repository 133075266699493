/* styles.css */
.center-table {
    margin-left: auto;
    margin-right: auto;
    width: 80%; /* Optional: Adjust width as needed */
    border: solid 1px white;
}

.gizmo{
    font-size: 10;
}
