body {
  background-color: #0D1218;
  padding: 0;
  margin: 0;
  color: #fff;
}

.btc-price {
  color: #f7931a;
}

.norm {
  text-decoration: none;
}

/* Style for the navigation container */
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(10,10,10,1);
  padding: 10px 20px;
  margin-bottom: 70px;
  position: relative;
}

/* Style for the logo container */
.logo-container {
  display: flex;
  align-items: center;
  flex-grow: 1; /* Added to ensure logo-container takes remaining space */
}

/* Style for the menu icon */
.menu-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: white;
  padding: 10px 20px;
}

/* Style for the navigation links */
.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

/* Style for each navigation item */
.nav-links li {
  padding: 14px 20px;
}

/* Style for each link */
.nav-links a {
  color: white;
  text-align: center;
  padding: 5px 20px;
  text-decoration: none;
  display: block;
}

#links {
  margin-top: 35px;
  color:#fff;
  text-decoration: none;
}

/* Change the color on hover */
#links:hover, #logo-link:hover {
  color: #f7931a;
}

.headerLogo {
  display: flex;
  align-items: center;
  border: none;
  padding: 10px 20px;
}

.logo-text {
  font-family: 'Bubblegum Sans', cursive;
  font-size: 24px;
  margin-left: 10px;
  color: #fff;
}

.logo {
  height: 75px;
  margin-right: 0;
}

.container {
  text-align: center;
}

.title {
  text-align: center;
  text-decoration: none;
}

.rotating-bitcoin {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  perspective: 1000px;
}

.rotating-bitcoin img {
  width: 100%;
  height: auto;
  animation: rotateCoin 3s infinite linear;
  transform-style: preserve-3d;
}

@keyframes rotateCoin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* update percent colors */
.up {
  color: green;
}

.down {
  color: red;
}

.btcETF {
  margin-bottom: 100px;
}

.footer {
  width:100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  text-align: center;
  align-items: center; /* Center vertically */
  height: 70px; /* Adjust the height as needed */
  background-color: #333; /* Optional: Add a background color */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    display: none; /* Hide nav links by default on mobile */
  }

  .nav-links.open {
    display: flex; /* Show nav links when menu is open */
  }

  .nav-links li {
    padding: 10px 0;
  }

  .headerLogo {
    justify-content: center;
    padding: 10px 0;
  }

  .logo {
    height: 50px;
  }

  .logo-text {
    font-size: 20px;
  
  }
  .footer{
    font-size: 12px;
    padding:8px;
  }
}

/* Media query for very small devices */
@media (max-width: 480px) {
  .headerLogo {
    flex-direction: column;
  }

  .logo {
    height: 40px;
  }

  .logo-text {
    font-size: 18px;
    margin-left: 0;
  }
  .footer{
    font-size: 10px;
    padding: 6px;
  }
}

/* Dropdown Styles */
.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
}

.nav-links li {
  margin: 0 10px;
  position: relative;
}

.store {
  margin-left: auto;
  text-decoration: none;
  color: inherit;
  font-size: 18px;
  padding-right: 120px;
}
